var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answer"},[_c('div',{staticClass:"answer-bg"},[_c('div',{staticClass:"answer-bg-model"},[_c('div',{staticClass:"answer-bg-model-n"},[_c('div',{staticClass:"answer-bg-model-n-name"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"answer-bg-model-n-sum"},[_vm._v("共"+_vm._s(_vm.total)+"题")])])])]),_c('div',{staticClass:"answer-content"},[_c('div',{staticClass:"answer-content-model"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"answer-content-model-subject"},_vm._l((_vm.ques_list),function(item,index){return _c('div',{key:index,staticClass:"quse_conter",attrs:{"id":'question' + item.id}},[_c('div',{staticClass:"title_name"},[_c('span',{staticClass:"style"},[_vm._v("("+_vm._s(item.type_name)+")")]),_c('div',{staticClass:"subject-1-n-subject"},[_c('div',{staticClass:"subject-1-n-subject-index-content"},[_c('span',{staticClass:"subject-1-n-subject-html",domProps:{"innerHTML":_vm._s(_vm.machiningHtml(item.html, index + 1))}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.type === 'radio' || item.type === 'checkbox'),expression:"item.type === 'radio' || item.type === 'checkbox'"}],staticClass:"radio"},_vm._l((item.options),function(it,idx){return _c('div',{key:idx,staticClass:"radio_item",on:{"click":function($event){return _vm.chooseOptionT(index, idx)}}},[_c('span',{staticClass:"letter",style:({
                  color: it.is_selected == true ? '#ffffff' : '#4574F6',
                  'background-color':
                    it.is_selected == true ? '#4574F6' : '#FFFFFF',
                })},[_vm._v(_vm._s(it.option_num))]),_c('span',{staticClass:"htmlP",domProps:{"innerHTML":_vm._s(it.title)}})])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.type === 'boolean'),expression:"item.type === 'boolean'"}],staticClass:"boolean"},[_c('div',{staticClass:"boolean-hover",on:{"click":function($event){return _vm.chooseBoolean(1, index)}}},[_c('span',{staticClass:"letter",style:({
                  color: item.boolean_answer === true ? '#ffffff' : '#4574F6',
                  'background-color':
                    item.boolean_answer === true ? '#4574F6' : '#FFFFFF',
                })},[_c('span',{staticClass:"iconfont el-icon-check"})]),_c('span',{staticClass:"htmlP"},[_vm._v("正确")])]),_c('div',{staticClass:"boolean-hover",on:{"click":function($event){return _vm.chooseBoolean(0, index)}}},[_c('span',{staticClass:"letter",style:({
                  color:
                    item.boolean_answer === false ? '#ffffff' : '#4574F6',
                  'background-color':
                    item.boolean_answer === false ? '#4574F6' : '#FFFFFF',
                })},[_c('span',{staticClass:"iconfont el-icon-close"})]),_c('span',{staticClass:"htmlP"},[_vm._v("错误")])])]),(
              item.type !== 'boolean' &&
              item.type !== 'radio' &&
              item.type !== 'checkbox'
            )?_c('div',{staticClass:"briefAnswer"},[_vm._v(" 本题暂不支持作答,请交卷后查看解析 ")]):_vm._e(),_c('div',{staticClass:"answer-content-model-subject-1-n-line"})])}),0),_c('div',{staticClass:"answer-content-model-statistics"},[_c('div',{staticClass:"answer-content-model-statistics-n"},[_c('div',{staticClass:"answer-content-model-statistics-n-ok"},[_vm._v(" 已做 "+_vm._s(_vm.answernum.length == 0 ? _vm.answer_num : _vm.answernum.length)+" 题 ")]),_c('div',{staticClass:"answer-content-model-statistics-n-no"},[_vm._v(" 未做 "+_vm._s(_vm.answernum.length == 0 ? _vm.total - _vm.answer_num : _vm.total - _vm.answernum.length)+" 题 ")]),_c('div',{staticClass:"answer-content-model-statistics-n-total"},[_vm._v(" 共 "+_vm._s(_vm.total)+" 题 ")])]),_c('div',{staticClass:"answer-content-model-subject-1-n-line"})]),_c('div',{staticClass:"answer-content-model-submit"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingSubmit),expression:"loadingSubmit"}],staticClass:"submit",on:{"click":function($event){return _vm.Submit()}}},[_vm._v(" 交卷 ")])])])]),_c('div',{staticClass:"answer-list"},[_c('answer-list',{attrs:{"ques_list":_vm.ques_list},on:{"Jump":_vm.Jump}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }