<template>
  <div>
    <div id="answerList-1" @mousedown.stop="move" :style="{'height':ispull?'50px':'200px'}" v-if="pageWidth > 1025">
      <div id="answerList" class="roll"  >
        <div class="an" v-for="(item,index) in ques_list" @click="answerListN(item.id)" :key="index">
          <div class="answerList-n1" v-if="item.has_answer">{{index+1}}</div>
          <div class="answerList-n" v-else>{{index+1}}</div>
        </div>
      </div>
      <div class="pull" @click="pull">
        <!-- <img src="@/assets/img/pull.png" alt /> -->
        <div class="pull-img"></div>
      </div>
    </div>
<!--    <div class="answer-list" @mousedown.stop="move" :style="{'height':ispull?'50px':'200px'}" v-else>-->
    <div class="answer-list" v-else>
      <el-button class="answer-btn" type="primary" :icon="ispull ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" circle @click="pull"></el-button>
      <div class="empty" v-show="ispull"></div>
      <transition name="el-zoom-in-bottom">
        <div class="answer-roll" v-show="ispull">
          <div class="answers" v-for="(item,index) in ques_list" @click="answerListN(item.id)" :key="index">
            <div class="answers-item checked" v-if="item.has_answer">{{index+1}}</div>
            <div class="answers-item" v-else>{{index+1}}</div>
          </div>
        </div>
      </transition>
      <div class="empty1" v-show="ispull"></div>
    </div>

  </div>
</template>

<script>
  import { mapState } from 'vuex';
export default {
  created() {
    //生命周期-组件创建（数据和方法都已经被创建）
  },
  props: {
    ques_list: {},
  
    //接收父组件的传值    props: ['fatherData'], ||  props: {fatherData:data,}
  },
  mounted() {
   
  },

  data() {
    //数据

    return {
        ispull:false,
    };
  },
  methods: {
     pull(){
       this.ispull=!this.ispull
      
    },
    move(e) {
      let odiv = e.target; //获取目标元素

      //算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      let firstTime = "";
      let lastTime = "";
      firstTime = new Date().getTime();
      document.onmousemove = e => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置

        this.ifD = false;

        let left = e.clientX - disX;
        let top = e.clientY - disY;

        //绑定元素位置到positionX和positionY上面
        this.positionX = top;
        this.positionY = left;

        //移动当前元素

        odiv.style.left = left + "px";
        odiv.style.top = top + "px";

        this.DifferenceX = left;
      };
      document.onmouseup = () => {
        lastTime = new Date().getTime();

        if (lastTime - firstTime > 200) {
          this.ifTime = false;
        } else {
          this.ifTime = true;
        }
        this.DifferenceXData = this.DifferenceX;

        document.onmousemove = null;
        document.onmouseup = null;
      };
    },

    //方法
    answerListN(index) {
      this.$emit("Jump", index);
    }
  },
  computed: {
    ...mapState(['pageWidth']),
  },
  watch: {
    ques_list: {
      handler() {
        // console.log(this.ques_list);
      },
      deep: true
    },
    pageWidth(val){
      console.log(val)
    }
  }
};
</script>

<style scoped>
#answerList-1 {
  width: 200px;
  height: 220px;
  user-select:none;  
  border: 1px #0c7aff solid;
  padding: 20px;
  position: fixed;
  background: white;
  top: 20%;
  cursor: Move;
  left: 2%;
  border-radius: 10px;
  box-shadow:0px 15px 10px -15px #0c7aff;
 
} 
.pull {
  width: 10px;
  height: 10px;
  align-items: center;
  line-height: 30px;
  margin: auto;
  padding: 10px;
  cursor: pointer;
  background: white;
  text-align: center;
  
 border: 1px #0c7aff solid;
  border-radius: 50%;

 margin-top:5px ;
 transition: 0.25s;
 
  
}
.pull:hover{
    box-shadow:0px 0px 10px #0c7aff;
    transition: 0.25s;
}
img {
  width: 100%;
  height: 100%;
}

#answerList {
  height: 99%;

  flex-wrap: wrap;
  display: flex;

  overflow-y: scroll;
  background: white;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
}
 
 
.answerList-n {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #0c7aff;
  background: white;
  margin: 5px 3px;
  border: 1px #0c7aff solid;
}

.answerList-n1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  background: #0c7aff;
  margin: 5px 3px;
  color: white;
  border: 1px #0c7aff solid;
}
.answerList-n1:hover{
  transition: 0.25s;
  box-shadow:0px 0px 10px #0c7aff;
}

.answerList-n:hover{
  transition: 0.25s;
  box-shadow:0px 0px 10px #0c7aff;
}
/* 滚动条 */

.roll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.roll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: none;
}

.roll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
}
.pull-img{
  width: 100%;
  height: 100%;
  background: url('../assets/img/pull1.png');
  background-size: cover;
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .answer-btn{
    font-size: 24px;
    position: fixed;
    right: 30px;
    bottom: 66px;
    z-index: 2001;
  }
  .el-button.is-circle{
    padding: 5px;
    color: #ffffff;
    background-color: #0c7aff;
    border-color: #0c7aff;
  }
  .answer-roll{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 140px);
    padding: 25px 40px;
    height: 210px;
    background-color: #fff;
    position: fixed;
    right: 0;
    bottom: 66px;
    left: 0;
    margin: 0 auto;
    overflow-y: scroll;
    border-radius: 4px 4px 20px 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)

  }
  .answers-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: #0c7aff;
    background: white;
    margin: 5px 3px;
    border: 1px #0c7aff solid;
  }
  ::-webkit-scrollbar{
    display: none;
  }
  .checked {
    background: #0c7aff;
    color: white;
  }
  .empty{
    width: calc(100% - 60px);
    height: 25px;
    background-color: #fff;
    border-radius: 4px 4px 4px 4px;
    position: fixed;
    right: 30px;
    bottom: 302px;
    z-index: 2000;
  }
  .empty1{
    width: calc(100% - 60px);
    height: 25px;
    background-color: #fff;
    border-radius: 4px 4px 20px 4px;
    position: fixed;
    right: 30px;
    bottom: 66px;
    z-index: 2000;
  }
}
</style>
