import request from '../request'


export function getSingle(ques_id, ans_num) {
    return request({
        url: `/answer/question/single?ques_id=${ques_id}&ans_num=${ans_num}`,
        method: 'get',
    })
}



//提交
export function postManagement(data) {
    return request({
        url: `/answer/mobile/management`,
        method: 'post',
        data: data
    })
}


//定时保存

export function postSave(data) {
    return request({
        url: `/answer/mobile/save`,
        method: 'post',
        data: data
    })
}