<template>
  <!--
    页面名称：我的题库-作答
    开发人：黄wj
  -->
  <div class="answer">
    <div class="answer-bg">
      <div class="answer-bg-model">
        <div class="answer-bg-model-n">
          <div class="answer-bg-model-n-name">{{ name }}</div>
          <div class="answer-bg-model-n-sum">共{{ total }}题</div>
        </div>
      </div>
    </div>

    <div class="answer-content">
      <div class="answer-content-model">
        <!-- 题目 -->
        <div class="answer-content-model-subject" v-loading="loading">
          <!-- 选择题 -->

          <div
            v-for="(item, index) in ques_list"
            :key="index"
            class="quse_conter"
            :id="'question' + item.id"
          >
            <div class="title_name">
              <!-- 题目类型 -->
              <span class="style">({{ item.type_name }})</span>
              <div class="subject-1-n-subject">
                <!-- 第几题 -->
                <!-- <span class="subject-1-n-subject-index">{{index+1}}.</span> -->
                <!-- 题目标题 -->
                <div class="subject-1-n-subject-index-content">
                  <span
                    v-html="machiningHtml(item.html, index + 1)"
                    class="subject-1-n-subject-html"
                  ></span>
                </div>
              </div>
            </div>

            <!-- 选项 -->
            <!-- 单/多选题 -->
            <div
              v-show="item.type === 'radio' || item.type === 'checkbox'"
              class="radio"
            >
              <div
                v-for="(it, idx) in item.options"
                :key="idx"
                class="radio_item"
                @click="chooseOptionT(index, idx)"
              >
                <span
                  class="letter"
                  :style="{
                    color: it.is_selected == true ? '#ffffff' : '#4574F6',
                    'background-color':
                      it.is_selected == true ? '#4574F6' : '#FFFFFF',
                  }"
                  >{{ it.option_num }}</span
                >
                <span class="htmlP" v-html="it.title"></span>
              </div>
            </div>

            <!-- 判断 -->
            <div v-show="item.type === 'boolean'" class="boolean">
              <div @click="chooseBoolean(1, index)" class="boolean-hover">
                <span
                  class="letter"
                  :style="{
                    color: item.boolean_answer === true ? '#ffffff' : '#4574F6',
                    'background-color':
                      item.boolean_answer === true ? '#4574F6' : '#FFFFFF',
                  }"
                >
                  <span
                    class="iconfont el-icon-check"
                  ></span>
                </span>
                <span class="htmlP">正确</span>
              </div>
              <div @click="chooseBoolean(0, index)" class="boolean-hover">
                <span
                  class="letter"
                  :style="{
                    color:
                      item.boolean_answer === false ? '#ffffff' : '#4574F6',
                    'background-color':
                      item.boolean_answer === false ? '#4574F6' : '#FFFFFF',
                  }"
                >
                  <span class="iconfont el-icon-close"></span>
                </span>
                <span class="htmlP">错误</span>
              </div>
            </div>

            <!-- 解析 -->
            <div
              v-if="
                item.type !== 'boolean' &&
                item.type !== 'radio' &&
                item.type !== 'checkbox'
              "
              class="briefAnswer"
            >
              本题暂不支持作答,请交卷后查看解析
            </div>

            <div class="answer-content-model-subject-1-n-line"></div>
          </div>
        </div>

        <!-- 统计 -->
        <div class="answer-content-model-statistics">
          <div class="answer-content-model-statistics-n">
            <div class="answer-content-model-statistics-n-ok">
              已做
              {{ answernum.length == 0 ? answer_num : answernum.length }} 题
            </div>
            <div class="answer-content-model-statistics-n-no">
              未做
              {{
                answernum.length == 0
                  ? total - answer_num
                  : total - answernum.length
              }}
              题
            </div>
            <div class="answer-content-model-statistics-n-total">
              共 {{ total }} 题
            </div>
          </div>
          <div class="answer-content-model-subject-1-n-line"></div>
        </div>
        <!-- 交卷 -->
        <div class="answer-content-model-submit">
          <div class="submit" v-loading="loadingSubmit" @click="Submit()">
            交卷
          </div>
        </div>
      </div>
    </div>

    <div class="answer-list">
      <answer-list :ques_list="ques_list" @Jump="Jump"></answer-list>
    </div>
  </div>
</template>

<script>
import answerList from "../../../model/answerList";
import { getSingle, postManagement, postSave } from "../../../http/api/answer";
export default {
  created() {
    //在data中定义的数据还没被绑定,所以不要在这里赋值
    this.getSingle();
  },
  mounted() {
    //定义数据已经被绑定  可以在这里进行数据ajax绑定
  },
  props: {
    //
  },
  destroyed() {
    clearInterval(this.interval);
  },
  data() {
    return {
      name: "",
      ques_id: "", //id  33
      ans_num: "", // 刷题次数
      answer_num: 0, //已做
      answernum: [], //已做的数量
      ques_list: [], //题目
      total: "0", //总共

      interval: null, //定时保存
      is_interval: true,
      loading: true,
      id: "",
      loadingSubmit: false,
    };
  },

  watch: {
    ques_list: {
      handler(newValue) {
        this.answernum = newValue.filter((item) => item.has_answer == true);
        // console.log("this.answernum", this.answernum);
      },
      deep: true,
    },
  },
  components: {
    answerList,
  },
  methods: {
    //得到数据（题目）
    getSingle() {
      this.id = this.$router.history.current.query.idDefor;
      this.title = this.$router.history.current.query.title;
      this.name = this.$router.history.current.query.name;
      this.ques_id = this.$router.history.current.query.id;
      this.ans_num = this.$router.history.current.query.ans_num;
      this.sum = this.$router.history.current.query.sum;
      // console.log("this.sum==========", this.sum);

      // console.log(this.$router.history.current.query);

      getSingle(this.ques_id, this.ans_num).then((res) => {
        if (res.code == 200) {
          // console.log(res);
          this.total = res.total;
          this.ques_list = res.ques_list;
          this.answer_num = res.answer_num;
          this.loading = false;
        }
      });
    },

    //改变样式 选择题
    chooseOptionT(index, idx) {
      let question = this.ques_list[index];
      let options = question.options;
      if (this.is_interval === true) {
        this.is_interval = false;
        this.setIntervalSubmit();
      }
      if (question.type === "radio") {
        if (question.has_answer === false) {
          question.has_answer = true;
        }
        if (options[idx].is_selected === false) {
          //将其他的取消选择
          for (let i = 0; i < options.length; i++) {
            if (options[i].is_selected === true) {
              options[i].is_selected = false;
            }
          }
          //选中
          options[idx].is_selected = true;
        }
      } else if (question.type === "checkbox") {
        if (options[idx].is_selected === false) {
          options[idx].is_selected = true;
          question.answer_num += 1;
        } else {
          options[idx].is_selected = false;
          question.answer_num -= 1;
        }
        question.has_answer = question.answer_num <= 0 ? false : true;
      }
    },
    //改变样式 判断题
    chooseBoolean(num, index) {
      let question = this.ques_list[index];
      question.boolean_answer = num === 1 ? true : false;
      question.has_answer = true;
      if (this.is_interval === true) {
        this.is_interval = false;
        this.setIntervalSubmit();
      }
    },
    //定时缓存
    setIntervalSubmit() {
      let that = this;

      this.interval = setInterval(function () {
        var data = {
          ques_id: that.ques_id,
          question_list: that.ques_list,
        };

        postSave(data).then((res) => {
          console.log(res);
        });
      }, 5000);
    },

    machiningHtml(item, index) {
      // console.log(index);

      var html = item.slice(0, 3) + `${index}.` + item.slice(3);
      // console.log(html);

      return html;
    },

    //锚点跳转
    Jump(index) {
      // console.log(index);
      this.$el.querySelector("#question" + index).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    Submit() {
      this.$confirm("是否交卷")
        .then(() => {
          this.loadingSubmit = true;
          var data = {
            ans_num: this.ans_num,
            ques_id: this.ques_id,
            question_list: this.ques_list,
          };

          postManagement(data).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.loadingSubmit = false;
              this.$router
                .push(
                  `statistics?ans_id=${res.ans_id}&name=${this.name}&title=${this.title}&sum=${this.sum}&id=${this.id}`
                )
                .then(() => {})
                .catch((err) => err);
              this.$message({
                message: "交卷成功！",
                type: "success",
              });
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.answer {
  width: 100%;
}
.answer-bg {
  width: 100%;
  height: 164px;
  background: url("../../../assets/img/analysis.png");
  background-size: cover;
}

.answer-bg-model {
  width: 67%;
  margin: auto;
  display: flex;
  padding-top: 5px;
  height: 155px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.answer-bg-model-n-name {
  color: white;

  text-align: center;
  font-size: 18px;
  margin-top: -15px;
}
.answer-bg-model-n-sum {
  margin-top: 12px;
  color: white;
  text-align: center;
  font-size: 14px;
}
.answer-content {
  width: 100%;
}
.answer-content-model {
  width: 65%;
  margin: auto;
  position: relative;
  top: -40px;
  overflow: hidden;
  border-radius: 5px;
  background: white;
  padding: 20px;
}
.answer-content-model-subject {
}
.answer-content-model-statistics {
  margin: 23px 0;
  padding-left: 10px;
}
.answer-content-model-submit {
  margin: 23px 0;
  margin-top: 40px;
  padding-left: 10px;
}
.submit {
  background: #0080ff;
  color: white;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  width: 84px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
}
.answer-content-model-subject-1-n {
  padding: 20px 0;
}
.answer-content-model-subject-1-n-line {
  width: 99%;
  height: 1px;
  background: #e3ebf3;
  margin-top: 23px;
  /* margin-left: 10px; */
}
.subject-1-n-subject {
  color: #00264d;
  display: flex;
  font-size: 18px;
  font-weight: 600;

  line-height: 27px;
  align-items: flex-start;

  margin: 8px 0;
}
.subject-1-n-category {
  color: #00264d;
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.subject-1-n-option {
  display: flex;
  align-items: center;

  cursor: pointer;
  padding-left: 10px;
}
.subject-1-n-option:hover {
  background: #f1f4f9;
}
.subject-1-n-option-id {
  width: 24px;
  height: 24px;
  background: #0080ff;
  border-radius: 50%;
  font-size: 13px;
  color: white;
  line-height: 24px;
  text-align: center;
  border: 1px solid #0080ff;
  margin-right: 16px;
}

.subject-1-n-option-id2 {
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  font-size: 13px;
  color: #0080ff;
  line-height: 24px;
  text-align: center;
  border: 1px solid #0080ff;
  margin-right: 16px;
}
.subject-1-n-option-name {
  font-size: 16px;
}
.answer-content-model-statistics-n {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.answer-content-model-statistics-n-ok {
  color: #0080ff;
}
.answer-content-model-statistics-n-no {
  color: #fc6257;
  margin: 0 18px;
}
.answer-content-model-statistics-n-total {
  color: #657489;
  margin-left: 21px;
}

/********************************************************** */
.boolean-hover{
  display: flex;
  font-size: 17px;
  color: #00264d;
  margin-bottom: 10px;
}
.letter {
  width: 24px;
  height: 24px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #0d80ff;
  border-radius: 50%;
  /* margin-right: 30px; */
}

.radio_item {
  display: flex;
  cursor: pointer;
  height: 40px;
  margin: 10px 0;
  align-items: center;
}
.radio_item:hover {
  transition: 0.5s;
  background: #f1f4f9;
}

.htmlP {
  display: block;
  font-size: 17px;
  color: #00264d;

  margin-left: 10px;
}
.subject-1-n-subject-html {
}
.title_name {
  font-size: 18px;
  color: #00264d;
}
.quse_conter {
  padding: 20px 0;
}
.subject-1-n-subject-index {
  display: block;
  padding: 18px 0;
}
.subject-1-n-subject-index-content {
}
.style {
  font-size: 18px;
  color: #00264d;
  font-weight: 400;
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .answer-bg-model{
    width: calc(100% - 40px);
    padding: 0 20px;
  }
  .answer-content-model {
    width: calc(100% - 80px);
    margin: 0 20px;
  }
}
</style>
